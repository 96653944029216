<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" :x-small="xSmall" :class="activatorClass" icon v-on="on">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-card :loading="isLoading">
      <v-card-title>
        {{ title || $t('help.tips') }}
        <v-icon v-if="priv(editPriv)" right @click="editMode=true">mdi-pencil</v-icon>
      </v-card-title> 
      <v-card-text>
        <div v-show="!isLoading">
          <div v-if="editMode">
            <tip-tap
              :label="$t('help.text') + ' (FR)'"
              v-model="internal.text.fr"
              extended
            ></tip-tap>
            <tip-tap
              :label="$t('help.text') + ' (EN)'"
              v-model="internal.text.en"
              extended
            ></tip-tap>
          </div>
          <div
            v-else-if="content"
            v-html="$langobj.parse(internal.text)">
          </div>
          <div v-else>
            <em>{{ $t('help.empty') }}</em>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-if="editMode">
          <v-btn color="primary" :loading="isSaving" @click="saveHelpText">
            {{ $t('label.save') }}
          </v-btn>
          <v-btn color="primary" text @click="editMode=false">
            {{ $t('label.cancel') }}
          </v-btn>
        </template>
        <v-btn v-else color="primary" text @click="dialog = false">
          {{ $t('label.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import TipTap from './TipTap.vue';
import {privileges} from '@/mixins/privileges.js';

export default Vue.extend({
  name: 'HelpDialog',
  components: {
    TipTap
  },
  mixins: [privileges],
  props: {
    helpKey: String,
    icon: {
      type: String,
      default: 'mdi-comment-question-outline'
    },
    activatorClass: String,
    editPriv: {
      type: String,
      default: 'agent'
    },
    title: String,
    xSmall: Boolean,
  },

  data () { return {
      dialog: false,
      editMode: false,
      content: false,
      internal: {key: null, text: { en: '', fr: ''}},
      isLoading: false, isSaving: false
    }
  },
  watch: {
    dialog() {
      if(this.dialog) {
        this.internal.key=this.helpKey;
        this.getHelpText();
      }
    }
  },
  methods: {
    getHelpText() {
      this.isLoading = true;

      this.$axios
        .get(this.$root.$data.server_base + '/api/admin/help_dialog/get.php',
          { params: { key: this.helpKey }})
        .then(response => {
          this.content = response.data.help_dialog;
          if(this.content)
            this.internal = _.cloneDeep(this.content);
          this.isLoading = false;
        })
    },
    saveHelpText() {
      this.isSaving = true;

      this.$axios
        .put(this.$root.$data.server_base + '/api/admin/help_dialog/upsert.php',
         this.internal)
        .then(response => {
          this.content = true;
          this.isSaving = false;
          this.editMode = false;
        })
    }
  }
  
});
</script>

<style scoped lang="scss">
</style>
